.contact-form-container {
    width: 100%;
    max-width: 600px; /* Increased container width */
    text-align: center;
    height: 100vh; /* Ensure full height, matching the viewport */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Add padding */
    box-sizing: border-box;
    overflow: hidden; /* Prevent internal scrolling */
    position: relative;
}

.contact-heading {
    font-size: 2rem;
    color: white;
    margin-bottom: 1.5rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Increased gap between form elements */
    width: 100%;
    max-width: 600px; /* Increased width of the form */
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.input-label {
    font-size: 0.875rem;
    color: white;
}

.input-field {
    width: 100%;
    padding: 1rem; /* Increased padding for larger input fields */
    border-radius: 0.5rem;
    background-color: #333;
    border: 2px solid transparent;
    color: white;
    font-size: 1.1rem; /* Larger font size for better readability */
    transition: box-shadow 0.3s ease, border 0.3s ease;
}

.input-field:focus {
    outline: none;
    border: 2px solid #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.6); /* Adds a glow effect on focus */
}

.submit-button {
    padding: 1rem; /* Larger button padding */
    background-color: #007bff;
    border: none;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    font-size: 1.1rem; /* Larger button text */
}

.submit-button:hover {
    background-color: #0056b3;
}

.thank-you-message {
    color: white;
    padding: 2rem;
}

.social-icons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.icon {
    color: white;
    transition: color 0.3s;
}

.icon:hover {
    color: #00f2ff;
}
