/* Global Styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: black;
  color: white;
}

/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
}

/* Sections */
section {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

section.visible {
  opacity: 1;
}

/* Hamburger Menu */
.hamburger-menu {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
}

.bar {
  width: 100%;
  height: 4px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu.open .bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

/* Overlay Menu */
.overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(30, 30, 30, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
  z-index: 999;
}

.overlay-menu.open {
  opacity: 1;
  pointer-events: auto;
}

.overlay-menu ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.overlay-menu ul li {
  margin: 20px 0;
}

.overlay-menu ul li a {
  font-size: 2rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.overlay-menu ul li a:hover {
  color: #00f2ff;
}

/* Mobile-Specific Styling */
@media only screen and (max-width: 768px) {
  /* Adjust Section Height for Smaller Screens */
  section {
    height: 80vh; /* Make sections shorter on mobile */
    padding: 1rem; /* Add padding for better spacing */
  }

  /* Hamburger Menu Adjustments */
  .hamburger-menu {
    top: 10px;
    right: 10px;
    width: 25px; /* Smaller width for the hamburger icon */
    height: 20px;
  }

  .bar {
    height: 3px; /* Thinner bars for the hamburger icon */
  }

  /* Overlay Menu Adjustments */
  .overlay-menu ul li {
    margin: 15px 0; /* Reduce margin between items */
  }

  .overlay-menu ul li a {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  /* Scroll Snapping on Mobile */
  html, body {
    scroll-snap-type: none; /* Disable scroll snapping on mobile for smoother scrolling */
  }
}

/* Very Small Screen Adjustments (Phones) */
@media only screen and (max-width: 480px) {
  .overlay-menu ul li a {
    font-size: 1.2rem; /* Further reduce font size on very small screens */
  }

  /* Reduce padding and margin to fit better */
  section {
    height: 70vh;
    padding: 0.5rem;
  }

  .hamburger-menu {
    top: 8px;
    right: 8px;
    width: 20px;
    height: 18px;
  }
}
