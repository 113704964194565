/* LandingPage.css */

.vertical-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    width: 100vw;  /* Full screen width */
    background-color: black;
    text-align: center; /* Horizontal centering */
    
    
  }
  
  h1 {
    font-family: "kokuryu", sans-serif;
    font-size: 8vw;  /* Increase the font size for the haiku */
    font-weight: 400;
    color: white;
    line-height: 1.2;
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin: 0;
    opacity: 1;
    display: inline-block;
    animation: fadeIn 2.8s ease forwards;
    transform: translateX(10px); /* Fade-in animation */
  }
  
  /* Slow fade-in effect */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px); /* Slight movement at the start */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Final position */
    }
  }
  
  /* Scroll indicator (optional) */
  .scroll-indicator::before {
    animation: bounce 1s ease infinite;
    bottom: 1rem;
    color: #fff;
    content: '╲╱'; /* Scroll indicator symbol */
    font-size: 1rem;
    height: 4rem;
    left: 50%;
    margin-left: -1rem;
    opacity: 0.8;
    position: absolute;
    text-align: center;
    width: 6rem;
    
  }
  
  @keyframes bounce {
    50% {
      transform: translateY(-50%);
    }
  }
  