.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  background: url('/src/assets/Artboard 1-1.png') center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  overflow: hidden; /* Prevent overflow issues during resizing */
}

/* Optional dark overlay for improved readability */
.video-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay with 60% opacity */
  z-index: 1;
}

.self-promo-video {
  max-width: 80%;
  max-height: 80%;
  border: 5px solid black;
  z-index: 2; /* Ensures the video stays above the overlay */
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.youtube-link-icon {
  position: absolute;
  top: 2%; /* Move closer to the video */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background: rgba(0, 0, 0, 0.6); /* Slight background for better contrast */
  padding: 0.5rem;
  border-radius: 50%;
  transition: transform 0.3s ease, background 0.3s ease;
  z-index: 3;
}

.youtube-link-icon:hover {
  transform: translateX(-50%) scale(1.1);
  background: rgba(0, 0, 0, 0.8);
}

/* Scroll Indicator */
.scroll-indicator::before {
  animation: bounce 1s ease infinite;
  bottom: 0.1rem;
  color: #fff;
  content: '╲╱'; /* Indicator symbol */
  font-size: 0.5rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem; /* Adjust for centering horizontally */
  opacity: 0.8;
  position: absolute;
  text-align: center;
  width: 6rem;
  transform: translateX(-50%); /* Center horizontally */
  z-index: 2; /* Ensures it is visible over the overlay */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0); /* Initial and end position */
  }
  50% {
    transform: translateY(-10px); /* How high the bounce goes */
  }
}
