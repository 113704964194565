/* Main container for the About Me section */
.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 20px;
  background-color: black;
  color: white;
  box-sizing: border-box;
}

/* Content wrapper for the profile image and text */
.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 20px;
}

.profile-image-wrapper {
  position: relative;
  margin-bottom: 30px;
}

.profile-image {
  width: 200px;
  height: 200px; /* Force the height to be equal to the width to maintain a perfect circle */
  border-radius: 50%; /* Ensure the image is circular */
  border: 3px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
  object-fit: cover; /* Ensures the image covers the container without being distorted */
  transition: transform 1s ease;
}

.profile-image:hover {
  transform: scale(1.05);
}

/* Introduction text */
.about-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: white;
  text-transform: uppercase;
}

.about-description {
  font-size: 1rem;
  max-width: 700px;
  line-height: 1.6;
  margin: 0 auto;
  color: #ccc;
  text-align: justify;
}

/* Experience Section */
.experience-section {
  width: 100%;
  max-width: 1000px;
  margin: 50px 0;
  padding: 0 20px;
}

/* Experience title */
.experience-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  text-transform: uppercase;
}

/* Horizontal Timeline styling */
.horizontal-timeline {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding-top: 20px;
}

/* Add the white line connecting dots */
.horizontal-timeline::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: white;
}

/* Each timeline item */
.timeline-item {
  position: relative;
  text-align: center;
  width: 30%;
}

/* Timeline dot */
.timeline-dot {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  height: 12px;
  width: 12px;
  background-color: #00f2ff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 242, 255, 0.5);
}

/* Timeline content styling */
.timeline-content {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
  margin-top: 10px;
}

.timeline-content h4 {
  font-size: 1.3rem;
  color: white;
}

.timeline-content p {
  font-size: 1rem;
  color: #ccc;
}

/* Media Query for Mobile Devices (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .about-content {
    padding: 0 10px; /* Reduce padding for smaller screens */
  }

  .profile-image {
    width: 150px; /* Reduce image size on mobile */
    height: 150px;
  }

  .about-title {
    font-size: 1.5rem; /* Reduce title font size on mobile */
  }

  .about-description {
    font-size: 0.9rem; /* Reduce description font size */
    text-align: center; /* Change to center text on mobile */
  }

  .experience-section {
    margin: 30px 0; /* Reduce margin for better spacing on mobile */
  }

  .experience-title {
    font-size: 1.5rem; /* Reduce title font size for mobile */
  }

  /* Change horizontal timeline to vertical layout on mobile */
  .horizontal-timeline {
    flex-direction: column; /* Stack timeline items vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .horizontal-timeline::before {
    top: auto;
    left: auto;
    right: auto;
    width: 3px; /* Turn the line into a vertical line */
    height: 100%;
    background-color: white;
    bottom: 0;
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .timeline-item {
    width: 100%;
    margin-bottom: 30px;
  }

  .timeline-dot {
    left: -30px; /* Adjust position for vertical layout */
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Very Small Screen Adjustments (max-width: 480px) */
@media only screen and (max-width: 480px) {
  .about-title {
    font-size: 1.2rem; /* Further reduce font size for very small screens */
  }

  .about-description {
    font-size: 0.85rem;
  }

  .experience-title {
    font-size: 1.3rem;
  }
}
