*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

.portfolio-section {
  text-align: center;
  color: white;
  margin-bottom: 2rem;
}

.portfolio-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Ensures it is centered both horizontally and vertically */
  text-transform: uppercase;
}

.wrapper {
  width: 100%;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

.inner {
  display: flex;
  gap: 20px;
  animation: scrollLeft 60s linear infinite;
  will-change: transform;
  position: relative;
  white-space: nowrap;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 2)); /* Smooth looping */
  }
}

.item {
  width: 400px;
  height: 280px;
  background-color: black;
  border-radius: 12px;
  flex-shrink: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: relative;
  transform-origin: center center;
  opacity: 1;
}

.item-title {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.item:hover {
  transform: scale(1.5);
  z-index: 10;
}

.item:not(:hover) {
  opacity: 0.9;
  transform: scale(0.8);
}

.wrapper:hover .inner {
  animation-play-state: paused;
}

.item-details {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: calc(100% + 10px); /* Ensures the text is placed below the image */
    left: 50%;
    transform: translateX(-50%);
    text-align: left; /* Left align the text inside the box */
    color: white;
    font-size: 0.8rem; /* Smaller font size to fit text */
    background-color: rgba(0, 0, 0, 0.7); /* Dark background for better contrast */
    padding: 0.5rem;
    border-radius: 6px;
    max-width: 400px; /* Set the width to match the card's width */
    width: 100%; /* Ensures the text container fits within the card */
    line-height: 1.4; /* Adjust line height for readability */
    word-wrap: break-word; /* Ensures long words break to fit within the width */
    white-space: normal; /* Allows the text to wrap to the next line */
  }
  
  .item:hover .item-details {
    opacity: 1; /* Make the text visible on hover */
  }
  
  

.image-slider {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
